<template>
    <div class="myNote">
        <el-tabs v-model="active" @tab-click="handleClick">
            <el-tab-pane label="我的笔记"></el-tab-pane>
        </el-tabs>
        <!-- <div class="myNote_header">
            <div class="myNote_header_left">我的笔记</div>
            <div class="myNote_header_right">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item>
                        <el-col class="first_dome">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col class="line">-</el-col>
                        <el-col class="end_dome">
                            <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item>
                </el-form>
            </div>
        </div>-->
        <div class="myNote_list" v-if="dataList.length">
            <div class="myNote_list_item" v-for="(item,index) in dataList" :key="index">
                <div class="myNote_list_item_time">{{ item.intime }}</div>
                <div class="myNote_list_item_text">{{ item.content }}</div>
                <div class="myNote_list_item_footer">
                    <p class="myNote_list_item_footer_left" style="width:100%;">
                        <img src="../../assets/mySelf/note-edit.png" alt @click="onEdit(item)" />
                        <img src="../../assets/mySelf/note-del.png" alt @click="del(item)" />
                        <span style="flex:auto;text-align:right;color:#999999;font-size:12px;font-family:SourceHanSansCN-Regular, SourceHanSansCN;">记录于：{{item.classHourName}}</span>
                    </p>
                    <!-- <p class="myNote_list_item_footer_right">
                        <span>记录与：</span>
                        <span>《初级会计实务》预科班第一节</span>
                    </p>-->
                </div>
            </div>
            <!-- <div class="myNote_list_item">
                <div class="myNote_list_item_time">2021-07-08</div>
                <div class="myNote_list_item_text">纳税申报表,是税务机关指定,由纳税人填写,以完成纳税申报程序的一种税收文书.一般应包括纳税人名称、税种、税目、应纳税项目、适用税率或单位税额、计税依据、应纳税款、税款属期等内容.增值税申报表还有进项税额、销项税额;所得税申报表还有销售收入、销售利润、应纳税所得额、应纳所得税额等.</div>
                <div class="myNote_list_item_footer">
                    <p class="myNote_list_item_footer_left">
                        <img src alt />
                        <img src alt />
                    </p>
                    <p class="myNote_list_item_footer_right">
                        <span>记录与：</span>
                        <span>《初级会计实务》预科班第一节</span>
                    </p>
                </div>
            </div>-->
            <div style="margin-top:10px;">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="count">
                    </el-pagination>
            </div>
        </div>
        <div class="noMore" v-else>
            <div class="coursediv_empty_img">
                <img src="@/assets/class_empty.png" alt="">
            </div>
            暂无数据
        </div>
        <div>
            <el-dialog title="编辑笔记" :visible.sync="dialogFormVisible">
            <el-input v-model="textarea" type="textarea" autosize="true" rows="6"></el-input>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmEdit">确 定</el-button>
            </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mine_notes, notes_edit, notes_del } from "../../api/myself";
export default {
    name: 'myNote',
    data() {
        return {
            active: 0,
            dataList: [],
            count:0,
            page:0,
            dialogFormVisible:false,
            textarea:'',
            editForm:{},
        }
    },
    mounted() {
        this.mine_notes();
    },
    methods: {
        handleCurrentChange(val){
            console.log(val);
            this.page = val-1
            this.mine_notes()
        },
        handleClick() { },
        mine_notes() {
            var obj={
                page:this.page,
            }
            mine_notes(obj).then(res => {
                this.dataList = res.data.data;
                this.count = res.data.count;
            })
        },
        confirmEdit(){
            this.edit(this.editForm.id, this.textarea);
        },
        onEdit(e) {
            this.editForm = e;
            this.textarea = this.editForm.content;
            this.dialogFormVisible=true
            

            // this.$prompt('编辑笔记', '', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     inputPlaceholder: '请输入您要编辑的内容',
            //     inputValue: e.content,
            //     inputPattern: /\S/,
            //     inputErrorMessage: '编辑内容不能为空'
            // }).then(({ value }) => {
            //     this.edit(e.id, value);
            // })
        },
        edit(id, value) {
            notes_edit({ noteId: id, content: value }).then(res => {
                if (res.data.code == 0) {
                    this.mine_notes();
                    this.$message.success('编辑成功');
                    this.dialogFormVisible=false;
                } else {
                    this.$message.error(res.data.msg);
                }

            })
        },
        del(e) {
            this.$confirm('确认要删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                notes_del({ noteId: e.id }).then(res => {
                    if (res.data.code == 0) {
                        this.mine_notes();
                        this.$message.success('删除成功');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            }).catch(() => {
                      
            });
            
        }
    }
}
</script>

<style scoped>
.noMore {
    text-align: center;
    width: 100%;
    color: #999;
    font-size: 14px;
    margin: 40px 0;
}

</style>